<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Kategori COA</b> yang ada di Klinik IONA
      </div>
      <!-- <b-button
        squared
        variant="success"
        @click="$router.push('/masters/coas/add')"
      >Tambah</b-button> -->
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-end">
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Berdasar Nama"
                    @keyup="filterByName"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>
            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive="sm"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(increased_by)='data'>
                {{(data.item.increased_by) ? 'kredit' : 'debit'}}
              </template>
              <template #cell(actions)='data'>
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="$router.push({ path: '/masters/coas/detail/' + data.item.id })"
                > <i class="fas fa-eye px-0"></i> </b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="$router.push({ path: '/masters/coas/edit/' + data.item.id })"
                > <i class="fas fa-edit px-0"></i> </b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                > <i class="fas fa-trash px-0"></i> </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: "List",

  components: {
    Card
  },

  data() {
    return {
      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fields: [
        {
          key: "list_number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "type",
          label: "Jenis",
          sortable: true,
        },
        {
          key: "increased_by",
          label: "increasedBy",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        // { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],      
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}`;
      let response = await module.paginateCoa(
        `coa-categories`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      
      let a, b
      b = this.perPage * (this.currentPage - 1) + 1  
      for(a = 0; a < response.data.length; a++){
        response.data[a].list_number = b + a
      }

      this.items = response.data   
    },

    filterByName() {
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('coas/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Kategori COA" }])
    // Get Data
    this.pagination()
  },

}

</script>
